import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import {
    doCreateEventUser,
    doFetchEvent, doListUsersByEvent, saveLocallyUpdatedEventToBackend,
    selectEventById, selectLocallyUpdatedEvent, selectUsersByEventId, updateLocalEvent,
} from "../../../slice/eventSlice";
import {doGetUser} from "../../../slice/userSlice";
import {prettyTimeDifference, toDanishDate} from "../../../component/date_functions";
import './event-client-tab.css'
import ClickToShow from "../../../component/click-to-show/ClickToShow";
import InputField from "../../../component/input-field/InputField";
import SimpleButton from "../../../component/simple-button/SimpleButton";
import {adminAPI, VenueAPI} from "../../../api";
import CenteredPopup from "../../../component/centered-popup/CenteredPopup";
import NewUser from "../../../component/new-user/NewUser";

export default function EventClientTab() {
    const {event_id, venue_id} = useParams()

    const [newPassword, setNewPassword] = useState('');
    const [passwordStatus, setPasswordStatus] = useState('');

    // new customer
    const [showAddNewCustomer, setShowAddNewCustomer] = useState(false);
    const [allVenueClients, setAllVenueClients] = useState(null);
    const [selectedUserToAdd, setSelectedUserToAdd] = useState('');

    const currentEvent = useSelector(selectEventById(event_id))
    const updatedEvent = useSelector(selectLocallyUpdatedEvent)
    const users = useSelector(selectUsersByEventId(event_id))

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(doGetUser())
        dispatch(doFetchEvent(event_id));
    }, [dispatch, event_id]);

    useEffect(() => {
        if (!showAddNewCustomer) return;
        VenueAPI.list_all_venue_clients(venue_id).then(setAllVenueClients)
    }, [showAddNewCustomer, venue_id]);

    const createEventUser = useCallback((values) => {
        dispatch(doCreateEventUser({
            ...values,
            event_id: currentEvent.id
        }))
    }, [dispatch, currentEvent]);

    if (!currentEvent || !updatedEvent) {
        return <div>Loading...</div>
    }

    return (<div className='event-client-tab'>
        <section className='event-note'>
            <h3>Velkomst besked til din kunde, når de logger ind:</h3>
            <textarea value={updatedEvent.note_to_client ?? ''}
                      onChange={v => dispatch(updateLocalEvent({
                          event_id: updatedEvent.id,
                          values: {'note_to_client': v.target.value}
                      }))}/>
            <SimpleButton value='Save' onClick={_ => dispatch(saveLocallyUpdatedEventToBackend)}
                          disabled={updatedEvent.note_to_client === currentEvent.note_to_client}/>
        </section>

        <section className='customer-wrapper'>
            <table>
                <thead>
                <tr>
                    <td colSpan={6}>
                        <SimpleButton value='Tilføj ny kunde' onClick={_ => setShowAddNewCustomer(true)}/>
                    </td>
                </tr>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>phone</th>
                    <th>Seated</th>
                    <th>Logged in</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {users.filter(u => u.role === 'venue-client').map(user => <tr key={user.id}>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.phone}</td>
                    {user.timestamp_seated &&
                        <td>{prettyTimeDifference(new Date(), new Date(user.timestamp_seated))}</td>}
                    {!user.timestamp_seated && <td> {'never'}</td>}
                    {user.login_timestamp &&
                        <td>{prettyTimeDifference(new Date(), new Date(user.login_timestamp))}</td>}
                    {!user.login_timestamp && <td>{'never'}</td>}
                    <td>
                        <ClickToShow buttonText='Sæt nyt password' buttonClassName='reset-btn' onOpen={_ => {
                            setNewPassword('')
                            setPasswordStatus('')
                        }}>
                            <h3>Resetting password for {user.name}</h3>
                            <br/>
                            <InputField value={newPassword} onChanged={setNewPassword} title='password'/>
                            <br/>
                            {passwordStatus === 'OK' && <p>Updated password.</p>}
                            <SimpleButton loading={passwordStatus === 'loading'} disabled={newPassword.length <= 3}
                                          value='Submit' onClick={_ => {
                                setPasswordStatus('loading')
                                adminAPI.patch_user(user.id, newPassword).then(_ => setPasswordStatus('OK'))
                            }}/>
                        </ClickToShow>
                    </td>
                    <td>
                        <SimpleButton value='Fjern bruger fra event' className='reset-btn' onClick={_ => {
                            if (window.confirm('Fjern bruger fra event?')) {
                                adminAPI.remove_user_from_event(user.id, event_id).then(_ => {
                                    dispatch(doListUsersByEvent(event_id));
                                })
                            }
                        }}/>
                    </td>
                </tr>)}
                </tbody>
            </table>
        </section>
        <CenteredPopup showPopup={showAddNewCustomer} closePopup={setShowAddNewCustomer}>
            <div className='add-user-to-event-popup-wrapper'>
                <NewUser title='Ny kunde' submit={createEventUser}/>
                <div className='add existing'>
                    <h2>Eller tilføj eksisterende</h2>
                    {allVenueClients && <InputField options={allVenueClients.map(e => e.email)}
                                                    value={selectedUserToAdd ? selectedUserToAdd.name : ''}
                                                    onChanged={email => setSelectedUserToAdd(allVenueClients.find(c => c.email === email))}
                                                    onlySelectableOptions/>}
                    <SimpleButton value='Add user to event' big disabled={!selectedUserToAdd} onClick={_ => {
                        adminAPI.add_user_to_event(selectedUserToAdd.id, event_id).then(_ => {
                            dispatch(doListUsersByEvent(event_id));
                        })
                    }}/>
                </div>
            </div>
        </CenteredPopup>
    </div>)
}
