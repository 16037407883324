import React, {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import './event-page.css'
import {Link, useNavigate, useParams} from "react-router-dom";
import {
    doFetchEvent, doListUsersByEvent,
    selectEventById,
    selectEventLoading,
    updateEvent,
} from "../../slice/eventSlice";
import {EventApi} from "../../api";
import SimpleButton from "../../component/simple-button/SimpleButton";
import RoomList from "../../component/room-list/RoomList";
import {doGetUser, selectUser} from "../../slice/userSlice";
import GuestList from "../../component/guest-list/GuestList";
import {doFetchEventGuests, selectGuests} from "../../slice/guestSlice";
import ImportGuestList from "../../component/import-guest-list/ImportGuestList";
import {
    doFetchEventRoom,
    doFetchEventRooms,
    selectActiveRoom,
    selectEventRooms,
    selectVenueRooms
} from "../../slice/roomSlice";
import SharedMessage from "../../component/shared-message/SharedMessage";
import EventSchedulePage from "./event-schedule/EventSchedulePage";

export default function EventPage() {
    const [seatingOverview, setSeatingOverview] = useState([]);

    const {event_id, venue_id} = useParams()

    const availableSeats = useMemo(() => {
        return seatingOverview.reduce((prev, curr) => prev + curr.active_seats, 0)
    }, [seatingOverview])

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const loading = useSelector(selectEventLoading)
    const room = useSelector(selectActiveRoom)
    const rooms = useSelector(selectEventRooms(event_id));
    const currentUser = useSelector(selectUser)

    const currentEvent = useSelector(selectEventById(event_id))

    const guests = useSelector(selectGuests)

    const guestCount = useMemo(() => guests.reduce((prev, curr) => prev + curr.count, 0), [guests])

    useEffect(() => {
        dispatch(doGetUser())
        dispatch(doFetchEvent(event_id));
        dispatch(doListUsersByEvent(event_id));
        dispatch(doFetchEventRooms(event_id));
        dispatch(doFetchEventGuests(event_id))

        EventApi.list_seating_overview(event_id).then(setSeatingOverview);
    }, [dispatch, event_id]);

    if (!currentUser || !currentEvent || loading || !room) {
        return <div className='loading'>
            <p>Loading...</p>
        </div>
    }

    if (currentUser.role === 'venue-client') {
        // return navigate(generatePath('/event/:event_id/seating', {event_id}))
    }

    return (<div className={'event-page'}>
        <EventSchedulePage />
        <section className='seating-overview-wrapper horizontal'>
            <div className='seating'>
                <h2>Seating</h2>
                <table>
                    <tbody>
                    <tr>
                        <th>Forventet antal gæster:</th>
                        <td>{currentEvent.expected_guests}</td>
                    </tr>
                    <tr>
                        <th>Gæster importeret af kunde:</th>
                        <td>{guestCount}</td>
                    </tr>
                    <tr>
                        <th>Pladser med nuværende opsætning:</th>
                        <td>{availableSeats}</td>
                    </tr>
                    </tbody>
                </table>
                <br/>
                {currentEvent.locked_seating ? <h2>Seating er <b>låst</b></h2> : <h2>Seating er åben</h2>}
                {currentEvent.locked_seating ?
                    <SimpleButton value='Lås seating op' onClick={_ => {
                        dispatch(updateEvent({
                            event_id: currentEvent.id, values: {
                                locked_seating: false
                            }
                        }))
                    }}/> :
                    <SimpleButton value='Lås seating for kunder' onClick={_ => {
                        dispatch(updateEvent({
                            event_id: currentEvent.id, values: {
                                locked_seating: true
                            }
                        }))
                    }}/>}
            </div>
            <div className='venue-room'>
                <h2>Lokaler</h2>
                <RoomList venue_id={venue_id} event_id={event_id} rooms={rooms} seating_link/>
            </div>
        </section>

        <ImportGuestList/>
        <GuestList displaySeated editable showSearch guests={guests}/>
    </div>)
}
