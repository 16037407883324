import React, {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Outlet, Route, Routes, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {
    doFetchEvent,
    selectEventById, selectLocallyUpdatedEvent,
} from "../../../slice/eventSlice";
import {doGetUser} from "../../../slice/userSlice";
import {toDanishDate} from "../../../component/date_functions";
import Tabs from "../../../component/tabs/Tabs";
import './event-page-wrapper.css'
import EventExport from "../event-export/EventExport";
import EventSignupAdminPage from "../event-signup-admin/EventSignupAdminPage";
import DietaryOverview from "../dietary-overview/DietaryOverview";
import EventClientTab from "../event-client-tab/EventClientTab";
import EventSettings from "../event-settings/EventSettings";
import EventSchedulePage from "../event-schedule/EventSchedulePage";
import EventPage from "../EventPage";
import WaiterList from "../waiter-list/WaiterList";
import DoorList from "../door-list/DoorList";

export default function EventPageWrapper() {
    const {event_id, venue_id} = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const currentEvent = useSelector(selectEventById(event_id))
    const updatedEvent = useSelector(selectLocallyUpdatedEvent)

    // Tab
    const [selectedTab, setSelectedTab] = useState(null);
    const [selectedSubtab, setSelectedSubtab] = useState(null);

    const dispatch = useDispatch()

    const urlToTabMap = useMemo(() => ({
        'home': {
            'title': 'Hjem',
        },
        'koreplan': {
            'title': 'Køreplan',
        },
        'venue-client': {
            'title': 'Venue klienter',
        },
        'export': {
            'title': 'Eksport',
            default: 'koreplan-eksport',
            'subtabs': {
                'koreplan-eksport': {
                    'title': 'Køreplan',
                },
                'dietary': {
                    'title': 'Diæter',
                },
                'waiter-export': {
                    'title': 'Waiter Export',
                },
                'door-list': {
                    'title': 'Dør liste',
                },
            }
        },
        'settings': {
            'title': 'Indstillinger',
        },
    }), []);

    const tabToUrlMap = useMemo(() => {
        const tabToUrl = {}
        for (const [key, value] of Object.entries(urlToTabMap)) {
            tabToUrl[value.title] = key
        }
        return tabToUrl
    }, [urlToTabMap]);

    const url = window.location.href;
    const end = url.split('/').pop();
    const [currentUrlTab, params] = end.split('?');

    const {tab, subtab} = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(doGetUser())
        dispatch(doFetchEvent(event_id));
    }, [dispatch, event_id]);

    useEffect(() => {
        if (selectedTab === null) {
            setSelectedTab(tab);
            return;
        }
        if (tab !== selectedTab || selectedSubtab !== subtab) {
            const subTabText = selectedSubtab ? '/' + selectedSubtab : '';
            navigate(`/app/venue/${venue_id}/event/${event_id}/${selectedTab}${subTabText}${params ? '?' + params : ''}`)
        }
    }, [tab, urlToTabMap, selectedTab, selectedSubtab, navigate, tabToUrlMap, params]);

    const renderTabComponent = useMemo(() => {
        switch (tab) {
            case 'home':
                return <EventPage/>;
            case 'venue-client':
                return <EventClientTab/>;
            case 'sign-up':
                return <EventSignupAdminPage/>;
            case 'settings':
                return <EventSettings/>;
            case 'koreplan':
                return <EventSchedulePage/>;
            case 'export':
                switch (subtab) {
                    case 'waiter-export':
                        return <WaiterList/>;
                    case 'dietary':
                        return <DietaryOverview/>;
                    case 'door-list':
                        return <DoorList/>;
                    default:
                        return <EventExport/>;
                }
            default:
                return <div>404: Tab not found</div>; // Fallback for invalid tabs
        }
    }, [tab, subtab]);

    if (!currentEvent || !updatedEvent) {
        return <div>Loading...</div>
    }

    return (<div className={'event-page-wrapper'}>
        <div className='header'>
            <div className='name-wrapper'>
                <button onClick={_ => {
                    if (Object.fromEntries(searchParams.entries()).ref === 'cal') {
                        console.log('BACK')
                        const date = new Date(currentEvent.date)
                        const y = date.getFullYear()
                        const m = date.getMonth() + 1
                        navigate(`/app/venue/${venue_id}/events/calendar?year=${y}&month=${m}`)
                    } else {
                        navigate(`/app/venue/${venue_id}/events`)
                    }
                }}>Tilbage
                </button>
                <h1>{currentEvent.title}</h1>
                <h5>{toDanishDate(currentEvent.date)} {currentEvent.timestamp_from && currentEvent.timestamp_from}-{currentEvent.timestamp_to && currentEvent.timestamp_to}</h5>
            </div>
        </div>

        <Tabs tabTitles={urlToTabMap}
              selected={tab}
              setSelected={t => {
                  setSelectedTab(t)
                  if (urlToTabMap[t].default) {
                      setSelectedSubtab(urlToTabMap[t].default)
                  } else {
                      setSelectedSubtab(null)
                  }
              }}
        />
        {urlToTabMap[tab].subtabs &&
            <Tabs tabTitles={urlToTabMap[tab].subtabs}
                  selected={subtab}
                  parentMenu={tab}
                  setSelected={setSelectedSubtab}
            />}
        {renderTabComponent}
    </div>)
}
