import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import {
    doCreateTable, doDeleteTable,
    doFetchRoomTables,
    doFetchTableTemplates,
    doSetHoveringTable, doSetStopDraggingTable,
    selectAllTables,
    selectHistoryActions, selectSelectedTables, thunkUndoAction
} from "../../slice/tableSlice";
import './room-page.css'
import TableEditWindow from "./table-edit-window/TableEditWindow";
import {
    doFetchRoom, doSetActiveRoomId, selectActiveRoom, selectZoom,
} from "../../slice/roomSlice";
import RoomTable from "../../component/room-table/RoomTable";
import {useKeyboardShortcut} from "../../UseKeyboardShortcut";
import Room from "../../component/room/Room";
import arrDownWhite from '../../assets/arr_down_white.svg';
import MeasuringTool from "../../component/measuring-tool/MeasuringTool";
import AlignmentControls from "../../component/room/alignment/AlignmentControls";
import ElementMenu from "../../component/room/element/element-menu/ElementMenu";
import DistanceLine from "../../component/room/element/representation/distance-line/DistanceLine";
import useElements from "../../component/room/element/useElements";
import {doListRoomElements, doSetContextMenu} from "../../slice/elementSlice";
import ElementEditMenu from "../../component/room/element/element-context-menu/ElementEditMenu";
import TableTemplateMenu from "../../component/room/table-template-menu/TableTemplateMenu";
import undoIcon from '../../assets/undo.svg';

export default function RoomPage({
                                     passed_room_id = null,
                                     hide_menus = false,
                                 }) {
    const [settingScale, setSettingScale] = useState(false);

    const [previewTables, setPreviewTables] = useState(null);

    // copied table
    const [placingTable, setPlacingTable] = useState(null);

    const zoom = useSelector(selectZoom)
    let {room_id} = useParams();
    useEffect(() => {
        if (passed_room_id) {
            room_id = passed_room_id
        }
        console.log('ACTIVE ROOM ID set to', room_id)
        dispatch(doSetActiveRoomId(room_id))
        dispatch(doFetchRoom(room_id))
    }, [room_id, passed_room_id]);

    const dispatch = useDispatch()
    const tables = useSelector(selectAllTables);
    const room = useSelector(selectActiveRoom);
    const elements = useSelector(state => state.element.elements);
    const selectedTables = useSelector(selectSelectedTables);

    const tableRef = useRef({});

    useEffect(() => {
        if (room === null) return;
        dispatch(doFetchRoomTables(room.id))
        dispatch(doListRoomElements(room.id))
        dispatch(doFetchTableTemplates({room_id: room.id, venue_id: null}))
    }, [dispatch, room]);

    // remove table refs for deleted tables, if any exist
    useEffect(() => {
        const tableIds = tables.map(t => t.id)
        Object.keys(tableRef.current).forEach(id => {
            if (!tableIds.includes(parseInt(id))) {
                tableRef.current[id] = null
            }
        })
    }, [tables]);

    const placeCopiedTable = useCallback(() => {
        dispatch(doCreateTable(placingTable));
        setPlacingTable(null);
        dispatch(doSetStopDraggingTable(-1))
    }, [dispatch, placingTable]);

    const placeTable = useCallback((placing) => {
        dispatch(doCreateTable(placing));
        dispatch(doSetStopDraggingTable(-1));
    }, [dispatch, placingTable]);

    const undoCallback = useCallback(_ => {
        dispatch(thunkUndoAction)
    }, [dispatch]);

    useKeyboardShortcut({
        shortcutKeys: ['Enter'], keyUpCallback: placeCopiedTable
    })

    useKeyboardShortcut({
        shortcutKeys: ['Delete', 'Backspace'],
        keyUpCallback: e => {
            // check we are not typing in an input field
            if (selectedTables.length === 0) return;
            if (window.confirm(`Er du sikker på at du vil slette ${selectedTables.length} bord${selectedTables.length === 1 ? '' : 'e'}?`)) {
                selectedTables.forEach(t => dispatch(doDeleteTable(t.id)))
            }
        }
    })

    useKeyboardShortcut({
        shortcutKeys: ['z'], ctrlKey: true, keyDownCallback: undoCallback
    })

    const {
        previewElement,
        createElement,
    } = useElements();

    const openSeatCount = useMemo(() => {
        return tables.reduce((prev, curr) => prev + curr.max_seating, 0)
    }, [tables]);

    useKeyboardShortcut({
        shortcutKeys: ['Escape'], keyUpCallback: _ => {
            setPlacingTable(null);
            dispatch(doSetStopDraggingTable(-1))
            dispatch(doSetStopDraggingTable('multiple'))
            dispatch(doSetContextMenu(null))
        }
    })

    const showMenus = useMemo(() => {
        return !settingScale && !hide_menus
    }, [settingScale, hide_menus]);

    if (!room) return null;

    return (<div className='room-page'>
        {showMenus && <div className='top-bar'>
            <Link className='back-btn' to={-1}><img src={arrDownWhite} alt='back'/> Tilbage</Link>
            <h4>{room.name}</h4>
            {!settingScale && <h4 className='scale-label'
                                  onClick={_ => setSettingScale(true)}>Sæt skalering</h4>}
            {settingScale && <h4 className='scale-label' style={{zIndex: 10}}
                                 onClick={_ => setSettingScale(false)}>Afslut skalering</h4>}
        </div>}

        <main>
            {showMenus && <div className='left-static-menu'>
                <TableTemplateMenu/>
                <h2>Elementer</h2>
                <ElementMenu/>
            </div>}
            <div className='room-wrapper' onContextMenu={e => {
                if (e.ctrlKey) {
                    e.preventDefault();
                    e.stopPropagation();
                }
            }}>
                <Room room_id={room.id}
                      dragToSelect
                      disable={!showMenus}
                      onDragStart={e => {
                          const createTableData = e.dataTransfer.getData('application/tableDragCreate')
                          if (createTableData) {
                              e.stopPropagation();
                              setPreviewTables(JSON.parse(createTableData))
                          }
                          console.log('DRAG START', createTableData)
                      }}
                      onDragOver={e => {
                          e.preventDefault();
                          if (e.dataTransfer.getData('application/tableDragCreate') && previewTables) {
                              e.stopPropagation();
                              setPreviewTables(prev => {
                                  const firstTable = prev[0]

                                  return prev.map(t => {
                                      const positionOffset = {
                                          x: t.position.x - firstTable.position.x,
                                          y: t.position.y - firstTable.position.y
                                      }
                                      return {
                                          ...t,
                                          position: {
                                              x: e.nativeEvent.offsetX + positionOffset.x,
                                              y: e.nativeEvent.offsetY + positionOffset.y
                                          }
                                      }
                                  })
                              })
                              return;
                          }

                          const creatingNewElement = e.dataTransfer.getData('creatingNewElement')
                          if (creatingNewElement) {
                              console.log('CREATING NEW ELEMENT', creatingNewElement)
                              previewElement(JSON.parse(creatingNewElement), {
                                  x: e.nativeEvent.offsetX,
                                  y: e.nativeEvent.offsetY
                              })
                              return;
                          }
                          dispatch(doSetHoveringTable(-1));
                      }}
                      onDrop={e => {
                          const creatingNewElement = e.dataTransfer.getData('creatingNewElement')
                          if (creatingNewElement) {
                              createElement(JSON.parse(creatingNewElement), {
                                  x: e.nativeEvent.offsetX,
                                  y: e.nativeEvent.offsetY
                              })
                              e.dataTransfer.clearData('creatingNewElement')
                              return;
                          }

                          const createTableData = e.dataTransfer.getData('application/tableDragCreate')
                          console.log('DROP', createTableData)
                          const tables = JSON.parse(createTableData)
                          const firstTable = tables[0]
                          let nextName = Math.max(...tables.map(t => parseInt(t.name))) + 1
                          if (isNaN(nextName)) nextName = 1
                          tables.forEach(t => {
                              const positionOffset = {
                                  x: t.position.x - firstTable.position.x,
                                  y: t.position.y - firstTable.position.y
                              }
                              placeTable({
                                  ...t,
                                  id: -1,
                                  room_id: room.id,
                                  name: nextName++ + '',
                                  position: {
                                      x: e.nativeEvent.offsetX + positionOffset.x,
                                      y: e.nativeEvent.offsetY + positionOffset.y
                                  }
                              })
                          })
                          setPreviewTables(null)
                      }}>
                    {tables.map(t => <RoomTable key={t.id}
                                                table={t}
                                                custRef={el => tableRef[t.id] = el}
                                                room={room}/>)}

                    <DistanceLine element_id={null} isPreview={true}/>

                    {Object.values(elements).map(e => {
                        if (e.type === 'line') {
                            return <DistanceLine key={e.id} element_id={e.id} editable/>
                        }
                    })}
                    {previewTables && previewTables.map((t, i) => <RoomTable pointer_events={false}
                                                                             key={`table_preview-${i}`}
                                                                             table={t} room={room}/>)}
                </Room>
                {settingScale && <MeasuringTool room_id={room.id} closeSetScale={_ => setSettingScale(false)}/>}
            </div>

            {showMenus && <div className='right-static-menu'>
                <TableEditWindow editingVenue={!!room.venue_id} editingEvent={!!room.event_id}/>
                <AlignmentControls/>
                <ElementEditMenu/>
                <div className='bottom-controls'>
                    <p>Pladser: {openSeatCount}</p>
                    <div className='horizontal'>
                        <div className='undo-wrapper'>
                            <img src={undoIcon} alt='undo' onClick={undoCallback}/>
                        </div>
                        <div className='zoom-wrapper'>
                            {/*<input*/}
                            {/*    type='range'*/}
                            {/*    value={Math.round((zoom-0.2)/10*800)}*/}
                            {/*    min={0}*/}
                            {/*    max={100}*/}
                            {/*    onChange={e => {*/}
                            {/*        const newZoom = (parseInt(e.target.value) / 800 * 10) + 0.2*/}
                            {/*    }}*/}
                            {/*/>*/}
                            {/*<label>{Math.min(Math.round((zoom-0.2)/10*800), 100)}% zoom</label>*/}
                        </div>
                    </div>
                </div>
            </div>}
        </main>
    </div>)
}
