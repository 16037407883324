import React, {useCallback, useEffect, useState} from 'react'
import {adminAPI, UserApi} from "../../../api";
import InputField from "../../../component/input-field/InputField";
import {prettyTimeDifference} from "../../../component/date_functions";
import SimpleButton from "../../../component/simple-button/SimpleButton";
import {useDispatch, useSelector} from "react-redux";
import {
    doAddUserToVenue,
    doDeleteUser,
    doFetchAdminUsers,
    doRemoveUserFromVenue,
    doSelectUser,
    selectAdminUsers,
    selectSelectedUser
} from "../../../slice/adminSlice";
import './admin-user.css';
import PowerTable from "../../../component/power-table/PowerTable";


export default function AdminUserSection({allVenues}) {
    const dispatch = useDispatch()

    const users = useSelector(selectAdminUsers)
    const selectedUser = useSelector(selectSelectedUser)

    const addOrRemoveUser = useCallback((user_id, venue_id, do_add) => {
        if (do_add) {
            dispatch(doAddUserToVenue({user_id, venue_id}))
        } else {
            dispatch(doRemoveUserFromVenue({user_id, venue_id}))
        }
    }, [dispatch]);

    return (<div className='user-admin-page'>
        {(!selectedUser) && <table>
            <thead>
            <tr>
                <th>Email</th>
                <th>Name</th>
                <th>Role</th>
                <th>Last login</th>
            </tr>
            </thead>
            <tbody>
            {[...users].sort((u1, u2) => {
                const u1ts = u1.login_timestamp ? new Date(u1.login_timestamp) : null;
                const u2ts = u2.login_timestamp ? new Date(u2.login_timestamp) : null;
                // Sort by last login
                if (u1ts && u2ts) {
                    return u1ts > u2ts ? -1 : 1
                }
                if (u1ts) return -1
                if (u2ts) return 1
                return u1.email > u2.email ? 1 : -1
            }).map(u => <tr key={u.id} onClick={_ => dispatch(doSelectUser(u))}>
                <td>{u.email}</td>
                <td>{u.name}</td>
                <td>{u.role}</td>
                <td>{u.login_timestamp && prettyTimeDifference(new Date(), new Date(u.login_timestamp))}</td>
            </tr>)}
            </tbody>
        </table>}

        {selectedUser && <div className='user'>
            <SimpleButton big value='Back' onClick={_ => dispatch(doSelectUser(null))}/>
            <label>name:</label>
            <h3>{selectedUser.name}</h3>

            <label>email:</label>
            <h3>{selectedUser.email}</h3>

            <label>role:</label>
            <h3>{selectedUser.role}</h3>

            <table>
                <tbody>
                <tr>
                    <th>Phone</th>
                    <th>Last login</th>
                    <th>Seated</th>
                </tr>
                <tr>
                    <td>{selectedUser.phone}</td>
                    <td>{selectedUser.login_timestamp &&
                        <label>{prettyTimeDifference(new Date(), new Date(selectedUser.login_timestamp))}</label>}</td>
                    <td>{selectedUser.timestamp_seated ?
                        <label>{prettyTimeDifference(new Date(), new Date(selectedUser.timestamp_seated))}</label> : 'never'}</td>
                </tr>
                </tbody>
            </table>

            <h1>Venues</h1>
            {allVenues && selectedUser.user_venues && <table>
                <tbody>
                {allVenues.map(venue => <tr key={venue.id}>
                    <td><input type='checkbox' checked={selectedUser.user_venues.some(v => v.id === venue.id)}
                               onChange={e => addOrRemoveUser(selectedUser.id, venue.id, e.target.checked)}
                    /></td>
                    <td>{venue.name}</td>
                </tr>)}
                </tbody>
            </table>}
            {selectedUser.role === 'venue-owner' && <p>
                {selectedUser.name} is a venue owner and therefore has access to all events for those venues.
            </p>}
            <h1>Events</h1>
            {selectedUser.user_events && <table>
                <tbody>
                {selectedUser.user_events.map(event => <tr key={event.id}>
                    <td>{event.title}</td>
                    <td>{allVenues.find(v => v.id === event.venue_id).name}</td>
                </tr>)}
                </tbody>
            </table>}
            <br/>
            <SimpleButton big red value='DELETE USER' onClick={_ => {
                if (window.confirm(`Delete user ${selectedUser.name}?`)) {
                    dispatch(doDeleteUser(selectedUser.id))
                }
            }}/>
        </div>}
    </div>)
}
