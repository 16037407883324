import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {doSetActiveRoomId, selectRoomById, setExternalNaturalImageSize} from "../../slice/roomSlice";
import {useKeyboardShortcut} from "../../UseKeyboardShortcut";
import './room.css'
import PanZoomHandle from "../navigatable/PanZoomHandle";
import {DragWindowHelper} from "../dragable-elements/DragWindowHelper";
import SelectedTablesRectMovable from "./selected-tables-rect-movable/SelectedTablesRectMovable";
import SelectedTablesRectSeating from "./selected-tables-rect-seating/SelectedTablesRectSeating";


export default function Room({
                                 children,
                                 onDragOver,
                                 onDrop,
                                 dragToSelect = false,
                                 room_id,
                                 seating = false,
                                 disable = false,
                                 roomClassName,
                                 ...props
                             }) {
    const [hideFloorplan, setHideFloorplan] = useState(false);

    const dispatch = useDispatch()

    const room = useSelector(selectRoomById(room_id))
    const roomRef = useRef(null);

    useEffect(() => {
        if (!room) return;
        dispatch(doSetActiveRoomId(room_id))
    }, [room_id]);

    // Hide background shortcut
    useKeyboardShortcut({
        shortcutKeys: ['b'], keyUpCallback: ev => {
            setHideFloorplan(p => !p)
        },
        validateEvent: e => e.ctrlKey
    })

    return (<PanZoomHandle room_id={room.id} disable={disable}>
        <div className='room'
             onDragOver={onDragOver}
             onDrop={onDrop}
             style={{pointerEvents: disable ? 'none' : 'auto'}}
             ref={roomRef} {...props}>
            <img
                onLoad={(img) => {
                    console.log('img', img.target.naturalWidth, img.target.naturalHeight)
                    dispatch(setExternalNaturalImageSize({
                        x: img.target.naturalWidth,
                        y: img.target.naturalHeight,
                    }))
                }}
                alt='floor plan'
                src={room.signed}
                style={{pointerEvents: 'none', userSelect: 'none', opacity: hideFloorplan ? '0' : '1'}}
            />
            {children}
            {!disable && dragToSelect && <>
                {!seating && <SelectedTablesRectMovable/>}
                {seating && <SelectedTablesRectSeating/>}
                <DragWindowHelper containerRef={roomRef}/>
            </>}
        </div>
    </PanZoomHandle>)
}
