import React, {useEffect, useMemo, useState} from 'react'
import './tab-styles.css'
import ClickToShow from "../../../component/click-to-show/ClickToShow";
import SimpleButton from "../../../component/simple-button/SimpleButton";
import CenteredPopup from "../../../component/centered-popup/CenteredPopup";
import CreateNewTableTemplate from "../../../component/create-table-template/CreateNewTableTemplate";
import RoomTable from "../../../component/room-table/RoomTable";
import InputField from "../../../component/input-field/InputField";
import {
    doDeleteTable,
    doFetchTableTemplates,
    doUpdateTable,
    selectVenueTableTemplates
} from "../../../slice/tableSlice";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

export default function ElementTab() {
    let {event_id, venue_id} = useParams();
    event_id = parseInt(event_id)
    venue_id = parseInt(venue_id)

    const dispatch = useDispatch();

    const [creatingNewTable, setCreatingNewTable] = useState(false);
    const [updatedTableValues, setUpdatedTableValues] = useState({});

    const tableTemplates = useSelector(selectVenueTableTemplates);

    useEffect(() => {
        if (!venue_id) return;
        dispatch(doFetchTableTemplates({room_id: null, venue_id: venue_id}))
    }, [venue_id]);

    return (<section>
        <CenteredPopup showPopup={creatingNewTable} closePopup={_ => setCreatingNewTable(false)}>
            <CreateNewTableTemplate venue_id={venue_id} onClose={_ => setCreatingNewTable(false)}/>
        </CenteredPopup>
        <div className='header'>
            <div>
                <h1>Standard Borde</h1>
                <p>De borde du ser og opretter her er tilgængelige i alle salopsætninger.</p>
            </div>
            <SimpleButton value='Opsæt nyt bord' onClick={_ => setCreatingNewTable(true)}/>
        </div>
        <div className='table-blur-wrapper'>
            <div className='tables'>
                {tableTemplates.map((t, idx) => <div key={t.id}
                                                     className='table-wrapper'
                                                     onDragStart={e => {
                                                         e.dataTransfer.setData('application/tableDragCreate', JSON.stringify(t))
                                                     }}>
                    <h2>{t.name}</h2>
                    <div className='table-model-wrapper'>
                        <div className={'positional-wrap'}>
                            <RoomTable table={{...t, position: {x: 0, y: 0}, name: ''}}
                                       room={{pixel_pr_meter: 90 / (t.length + (t.type === 'Round' ? t.seat_size * 2 : 0))}}
                                       draggable={false}
                                       active={false}
                                       pointer_events={false}
                            />
                        </div>
                    </div>

                    <ClickToShow buttonText='Rediger' className='create-new-table-wrapper'
                                 onOpen={_ => setUpdatedTableValues(t)}>
                        <h3>{t.name}</h3>
                        <p>Type: {t.max_seating}-{t.type}</p>
                        <InputField
                            title='Table name'
                            value={updatedTableValues.name}
                            onChanged={n => setUpdatedTableValues({...t, name: n})}
                        />
                        <InputField
                            title='Max seated for this event'
                            value={updatedTableValues.active_seats}
                            onChanged={n => setUpdatedTableValues({...t, active_seats: n})}
                        />
                        <InputField
                            title='Max table seats'
                            value={updatedTableValues.max_seating}
                            onChanged={n => setUpdatedTableValues({...t, max_seating: n})}
                        />
                        <InputField
                            title='Width'
                            value={updatedTableValues.width}
                            onChanged={n => setUpdatedTableValues({...t, width: n})}
                        />
                        <InputField
                            title='Length'
                            value={updatedTableValues.length}
                            onChanged={n => setUpdatedTableValues({...t, length: n})}
                        />
                        <InputField
                            title='Seat size'
                            value={updatedTableValues.seat_size}
                            onChanged={n => setUpdatedTableValues({...t, seat_size: n})}
                        />

                        <SimpleButton value='GEM'
                                      disabled={JSON.stringify(updatedTableValues) === JSON.stringify(t)}
                                      onClick={_ => {
                                          dispatch(doUpdateTable({
                                              table_id: t.id,
                                              values: updatedTableValues
                                          }))
                                      }}/>
                    </ClickToShow>

                    <SimpleButton dangerous onClick={_ => {
                        if (window.confirm('Er du sikker på at du vil slette dette bord?')) {
                            dispatch(doDeleteTable(t.id))
                        }
                    }} value='slet'/>
                </div>)}
            </div>
            <div className='right-side-blur'>
                &nbsp;
            </div>
        </div>
    </section>)
}
