import React, {useCallback, useState} from 'react';
import './alignment-controls.css';
import align_center_icon from '../../../assets/alignment-icons/align_inner.svg';
import align_outer_icon from "../../../assets/alignment-icons/align_outer.svg";
import space_evenly_icon from "../../../assets/alignment-icons/space_evenly.svg";
import {useSelector} from "react-redux";
import {
    selectSelectedTableRect, selectSelectedTables
} from "../../../slice/tableSlice";
import useSetTablePosition from "../useSetTablePosition";

export default function AlignmentControls() {
    const selectedTables = useSelector(selectSelectedTables);
    const selectedTablesRect = useSelector(selectSelectedTableRect);
    const room = useSelector(state => state.room.rooms[state.room.active_room_id]);

    const [horizontalSpacing, setHorizontalSpacing] = useState(0.5);
    const [verticalSpacing, setVerticalSpacing] = useState(0);

    const leftAlignVideo = '/alignment-videos/left-align.mp4'
    const rightAlignVideo = '/alignment-videos/right-align.mp4'
    const spaceEvenlyHorizontalVideo = '/alignment-videos/space-even-hori.mp4'
    const spaceEvenlyVerticalVideo = '/alignment-videos/space-even-vert.mp4'
    const topAlignVideo = '/alignment-videos/top-align.mp4'
    const botAlignVideo = '/alignment-videos/bot-align.mp4'

    const [hoveringButton, setHoveringButton] = useState(null);

    const {setMultipleLocally, updateBackend} = useSetTablePosition()

    const doAlignTables = useCallback((axis, position) => {
        // depending on axis, we want to update all tables x or y position
        // the position decides which new value x or y will take.
        let updatedPosition = {}

        if (axis === 'x') {
            updatedPosition.x = Math.floor(selectedTablesRect.alignment[axis][position])
        } else {
            updatedPosition.y = Math.floor(selectedTablesRect.alignment[axis][position])
        }

        const rotatedTables = selectedTables.map(t => {
            if (!t) return null;
            let totalSeatWidth = t.active_seats > 0 ? t.seat_size * 2 : 0;
            if (t.type === 'Cinema') totalSeatWidth = 0;
            const halfWidth = ((t.width + totalSeatWidth) * room.pixel_pr_meter) / 2;
            const halfLength = (t.length + (t.type === 'Round' ? t.seat_size * 2 : 0)) * room.pixel_pr_meter / 2;
            const x0 = t.position.x
            const y0 = t.position.y
            const theta = (t.rotation || 0) * (Math.PI / 180); // Convert degrees to radians
            const cosTheta = Math.cos(theta);
            const sinTheta = Math.sin(theta);

            const corners = [
                {x: -halfWidth, y: -halfLength},
                {x: halfWidth, y: -halfLength},
                {x: halfWidth, y: halfLength},
                {x: -halfWidth, y: halfLength}
            ];

            const xs = [];
            const ys = [];
            corners.forEach(corner => {
                const x = x0 + corner.x * cosTheta - corner.y * sinTheta;
                const y = y0 + corner.x * sinTheta + corner.y * cosTheta;
                xs.push(x);
                ys.push(y);
            });

            const left = Math.min(...xs);
            const top = Math.min(...ys);
            const right = Math.max(...xs);
            const bottom = Math.max(...ys);

            const width = right - left;
            const height = bottom - top;

            return {
                ...t,
                position: {
                    x: left + width / 2,
                    y: top + height / 2
                },
                total_room_width: width,
                total_room_height: height,
                rotation: 0
            }
        });

        const sortedTables = [...rotatedTables.sort((a, b) => {
            if (axis === 'x') {
                return (a.position.x - b.position.x) * (position === 'start' ? 1 : -1)
            }
            return (a.position.y - b.position.y) * (position === 'start' ? 1 : -1)
        })];

        const finalPositionUpdates = [];
        if (axis === 'x') {
            const rectHeight = Math.floor(selectedTablesRect.height)

            const xCollisionOffsetArray = new Array(rectHeight).fill(0);
            sortedTables.forEach((t, idx) => {
                const tableToRectTop = t.position.y - selectedTablesRect.top - t.total_room_height/2;

                const horizontalSpacingPixels = Math.floor(parseFloat(horizontalSpacing) * room.pixel_pr_meter);

                // highest collision offset spanning the length of the table
                let xCollisionOffset = xCollisionOffsetArray.slice(tableToRectTop, tableToRectTop + t.total_room_height).reduce((acc, val) => Math.max(acc, val), 0);

                xCollisionOffsetArray.fill(xCollisionOffset + t.total_room_width + horizontalSpacingPixels, tableToRectTop, tableToRectTop + t.total_room_height);

                const tableAlignment = {...updatedPosition}
                const diff = Math.floor((t.total_room_width)/2 + xCollisionOffset);
                if (position === 'start') {
                    tableAlignment.x += diff;
                } else if (position === 'end') {
                    tableAlignment.x -= diff;
                }

                const newpos = {...t.position, ...tableAlignment}

                finalPositionUpdates.push({
                    id: t.id,
                    position: newpos
                });
            })
        } else {
            const rectWidth = Math.floor(selectedTablesRect.width)

            const yCollisionOffsetArray = new Array(rectWidth).fill(0);
            sortedTables.forEach(t => {
                const tableToRectLeft = t.position.x - selectedTablesRect.left - t.total_room_width/2;

                const verticalSpacingPixels = Math.floor(parseFloat(verticalSpacing) * room.pixel_pr_meter);

                const yCollisionOffset = yCollisionOffsetArray.slice(tableToRectLeft + 5, tableToRectLeft + t.total_room_width - 5).reduce((acc, val) => Math.max(acc, val), 0);

                yCollisionOffsetArray.fill(yCollisionOffset + t.total_room_height + verticalSpacingPixels, tableToRectLeft + 5, tableToRectLeft + t.total_room_width - 5);

                const tableAlignment = {...updatedPosition}
                const diff = Math.floor((t.total_room_height) / 2 + yCollisionOffset);
                if (position === 'start') {
                    tableAlignment.y += diff + 1;
                } else if (position === 'end') {
                    tableAlignment.y -= diff;
                }

                const newpos = {...t.position, ...tableAlignment}
                // setLocally(t.id, newpos)
                finalPositionUpdates.push({
                    id: t.id,
                    position: newpos
                });
            })
        }

        setMultipleLocally(finalPositionUpdates)
        updateBackend()
    }, [selectedTables, selectedTablesRect, room, horizontalSpacing, verticalSpacing, setMultipleLocally, updateBackend]);

    const spaceEvenly = useCallback((axis) => {
        const positions = selectedTables.map(t => t.position[axis])
        const min = Math.min(...positions)
        const max = Math.max(...positions)
        const space = (max - min) / (selectedTables.length - 1);

        const finalPositions = [...selectedTables.sort((a, b) => axis === 'x' ? a.position.x - b.position.x : a.position.y - b.position.y)].map((t, i) => {
            const newpos = {...t.position}
            newpos[axis] = min + i * space
            return {
                id: t.id,
                position: newpos
            }
        })

        setMultipleLocally(finalPositions)
        updateBackend()
    }, [selectedTables, room, setMultipleLocally, updateBackend]);

    if (selectedTablesRect === null || selectedTables.length <= 1) return null;

    return (<div className='alignment-controls'>
            <h5>Align</h5>
            <h5>Horizontal Spacing</h5>
            <input type='number' value={horizontalSpacing} onChange={e => setHorizontalSpacing(e.target.value)}/>

            <h5>Vertical Spacing</h5>
            <input type='number' value={verticalSpacing} onChange={e => setVerticalSpacing(e.target.value)}/>

            <div className='videos' onMouseLeave={_ => setHoveringButton(null)}>
                {['x', 'y'].map(axis => {
                    return ['start', 'end'].map(position => {
                        const icon = position === 'center' ? align_center_icon : align_outer_icon;

                        let rotation = axis === 'y' ? 90 : 0;
                        let doFlip = position === 'end';

                        return <img src={icon}
                                    key={`${axis}-${position}`}
                                    onMouseEnter={() => setHoveringButton({
                                        text: `${axis === 'x' ? 'Horisontalt' : 'Vertikalt'} ${position === 'start' ? 'start' : 'slut'}`,
                                        video: axis === 'x' ? (position === 'start' ? leftAlignVideo : rightAlignVideo) : (position === 'start' ? topAlignVideo : botAlignVideo)
                                    })}
                                    onClick={() => doAlignTables(axis, position)}
                                    style={{
                                        transform: `rotate(${rotation}deg) scaleX(${doFlip ? -1 : 1})`
                                    }}
                        />
                    })
                })}
                {['x', 'y'].map(axis => {
                    return <div className='icon-tooltip-wrapper' key={`space-even-${axis}`}>
                        <img src={space_evenly_icon}
                             onMouseEnter={() => setHoveringButton({
                                 text: `${axis === 'x' ? 'Horisontalt' : 'Vertikalt'} 'Spredt ligeligt'`,
                                 video: axis === 'x' ? spaceEvenlyHorizontalVideo : spaceEvenlyVerticalVideo
                             })}
                             style={{transform: axis === 'y' ? 'rotate(90deg)' : ''}}
                             onClick={() => spaceEvenly(axis)}/>
                    </div>
                })}
            </div>
            <div className='alignment-tooltip'>
                {hoveringButton && <>
                    <p>{hoveringButton.text}</p>
                    <div className='video-wrapper'>
                        {[leftAlignVideo, rightAlignVideo, topAlignVideo, botAlignVideo, spaceEvenlyHorizontalVideo, spaceEvenlyVerticalVideo].map((video, i) => {
                            if (video !== hoveringButton.video) return null;
                            return <video className='video-tooltip' key={video} src={video} autoPlay loop
                                          muted/>
                        })}
                    </div>
                </>}
            </div>
        </div>
    )
}
