import React from 'react'
import './tabs.css'

export default function Tabs({tabTitles, selected, setSelected, parentMenu}) {
    return (<div className={`tab-container ${parentMenu ? 'submenu' : ''}`}>
        {Object.entries(tabTitles).map(([url, menu]) => {
            return <div key={menu.title}
                        onClick={() => setSelected(url)}
                        className={`tab ${selected === url ? 'selected' : ''} ${menu.subtabs ? 'parent' : ''}`}>
                <span>{menu.title}</span>
            </div>
        })}
    </div>)
}
