import React, {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {doFetchEvent, selectEventById} from "../../../slice/eventSlice";
import {doGetUser} from "../../../slice/userSlice";
import {doFetchEventRooms, selectEventRooms} from "../../../slice/roomSlice";
import './event-export.css';
import EventSchedulePage from "../event-schedule/EventSchedulePage";
import {selectGuests} from "../../../slice/guestSlice";
import {EventApi} from "../../../api";
import {toDanishDate} from "../../../component/date_functions";

export default function EventExport() {
    const dispatch = useDispatch();
    const [seatingOverview, setSeatingOverview] = useState(null);

    let {event_id, venue_id} = useParams();
    event_id = parseInt(event_id);
    venue_id = parseInt(venue_id);
    const currentEvent = useSelector(selectEventById(event_id));
    const rooms = useSelector(selectEventRooms(event_id));

    useEffect(() => {
        dispatch(doGetUser())
        dispatch(doFetchEvent(event_id));
        dispatch(doFetchEventRooms(event_id));
        EventApi.list_seating_overview(event_id).then(setSeatingOverview);
    }, [dispatch, event_id]);

    const guests = useSelector(selectGuests)
    const guestCount = useMemo(() => guests.reduce((prev, curr) => prev + curr.count, 0), [guests])

    const availableSeats = useMemo(() => {
        if (!seatingOverview) return 0;
        return seatingOverview.reduce((prev, curr) => prev + curr.active_seats, 0)
    }, [seatingOverview]);

    function adjustHeight(textarea) {
        textarea.style.height = "auto"; // Reset height
        textarea.style.height = textarea.scrollHeight + "px"; // Set height to content
    }

    useEffect(() => {
        const handleBeforePrint = () => {
            const textareas = document.querySelectorAll("textarea");
            textareas.forEach((textarea) => {
                textarea.style.height = "auto";
                textarea.style.height = `${textarea.scrollHeight}px`;
            });
        };

        // Attach event listeners
        window.addEventListener("beforeprint", handleBeforePrint);

        // Cleanup event listeners on unmount
        return () => {
            window.removeEventListener("beforeprint", handleBeforePrint);
        };
    }, []); // Empty dependency array to run only on mount/unmount

    return (<div className='event-export'>
        <p className='dont-print'>
            <b>Denne side er printer venlig - tryk ctrl+p (cmd+p på mac) for at åbne print siden, derefter kan du vælge
                "Gem som pdf" eller
                bare printe direkte.</b>
        </p>
        <h1>{currentEvent.title}</h1>
        <h5>{toDanishDate(currentEvent.date)} {currentEvent.timestamp_from && currentEvent.timestamp_from}-{currentEvent.timestamp_to && currentEvent.timestamp_to}</h5>
        <EventSchedulePage no_edit/>
        <h2>{currentEvent.name}</h2>

        <table>
            <tbody>
            <tr>
                <th>Forventet antal gæster:</th>
                <td>{currentEvent.expected_guests}</td>
            </tr>
            <tr>
                <th>Gæster importeret af kunde:</th>
                <td>{guestCount}</td>
            </tr>
            <tr>
                <th>Pladser med nuværende opsætning:</th>
                <td>{availableSeats}</td>
            </tr>
            </tbody>
        </table>
    </div>);
}
