import React, {useCallback, useMemo, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
    doSeatGuests,
    doSetHoveringTable,
    doSetHoveringTableOverride,
    doSetStopHoveringTable,
    selectHoveringTableId,
    selectHoveringTableOverride
} from "../../slice/tableSlice";
import Dragable from "../dragable-handle/Dragable";
import './seating-table.css'
import {doSetDraggingData, selectDraggingData} from "../../slice/seatingSlice";
import {selectCountByGuestIds} from "../../slice/guestSlice";

export default function SeatingTable({table, room, onClick}) {
    const dispatch = useDispatch()
    const hoveringTableId = useSelector(selectHoveringTableId)

    const [dragHovering, setDragHovering] = useState('');
    const hoveringTableOverride = useSelector(selectHoveringTableOverride);

    const draggingData = useSelector(selectDraggingData);

    const tableCount = useMemo(() => {
        return Object.values(table.seats).reduce((acc, c) => acc + c, 0)
    }, [table.seats])

    const onDrop = useCallback(e => {
        e.preventDefault();
        const data = e.dataTransfer.getData('application/seating')
        const {guest_ids, count} = JSON.parse(data)
        const openSeats = table.active_seats - tableCount

        if (openSeats >= count) {
            dispatch(doSeatGuests({table_id: table.id, guest_ids}))
        }
        dispatch(doSetDraggingData(null))
        dispatch(doSetHoveringTable(-1))
        dispatch(doSetHoveringTableOverride({}))
    }, [dispatch, table, tableCount]);

    const onDragOver = useCallback(e => {
        e.preventDefault()
        const openSeats = table.active_seats - tableCount

        const {guest_ids, count_pr_guest} = draggingData
        const total__count = count_pr_guest.reduce((acc, c) => acc + c, 0)

        let override = {
            [table.id]: {}
        }
        guest_ids.forEach((g, i) => {
            override[table.id][g] = count_pr_guest[i]
        })

        dispatch(doSetHoveringTable(table.id))
        dispatch(doSetHoveringTableOverride(override))
        if (openSeats < total__count) {
            e.dataTransfer.dropEffect = 'none'
            setDragHovering('none')
        } else {
            e.dataTransfer.dropEffect = 'link'
            setDragHovering('link')
        }
    }, [dispatch, draggingData, table, tableCount]);

    const onDragLeave = useCallback(table_id => {
        setDragHovering('')
        dispatch(doSetStopHoveringTable(table_id))
        dispatch(doSetHoveringTableOverride({}))
    }, [dispatch])

    const hovering = hoveringTableId === table.id

    const openSeats = useMemo(() => {
        if (!!hoveringTableOverride[table.id]) {
            const planned = Object.values(hoveringTableOverride[table.id]).reduce((acc, c) => acc + c, 0)
            return table.active_seats - tableCount - planned
        }
        return table.active_seats - tableCount
    }, [table, tableCount, hoveringTableOverride]);

    const seatTakenColor = useMemo(() => {
        return 'rgb(213,51,51)'
    }, []);

    const seatAvailableColor = useMemo(() => {
        return 'rgb(79,169,8)'
    }, []);

    return (<Dragable
        onDrop={onDrop}
        onDragOver={onDragOver}

        pointer_events={true}

        // onMouseEnter={_ => dispatch(doSetHoveringTable(table.id))}
        onMouseLeave={_ => onDragLeave(table.id)}
        onDragLeave={_ => onDragLeave(table.id)}

        onClick={onClick ?? (_ => {})}

        element_id={table.id}
        key={table.id}
        className={`
            seating-table 
            ${table.type} 
            ${table.dirty && 'dirty'} 
            ${hovering ? `hovering-${dragHovering}` : ''} 
            ${table.id === -1 && 'being-placed'}
            ${openSeats === table.active_seats && 'none-seated'}
            ${openSeats < table.active_seats && openSeats > 0 && 'half-seated'}
            ${openSeats <= 0 && 'completely-seated'}
        `}

        position={table.position}
        rotation={table.rotation}
        style={{
            width: `${table.width * room.pixel_pr_meter}px`,
            height: `${table.length * room.pixel_pr_meter}px`,
            transform: `translateX(-50%) translateY(-50%) rotate(${table.rotation}deg)`,
            background: table.surface_color,
        }}>
        {table.type === 'Square' && <>
            <div className='seats left' style={{
                left: -table.seat_size * room.pixel_pr_meter + 'px',
            }}>
                {new Array(Math.floor(table.active_seats / 2)).fill(0).map((_, i) => <div key={`left-${i}`}
                                                                                          className='chair' style={{
                    width: `${table.seat_size * room.pixel_pr_meter - 2}px`,
                    height: `${table.seat_size * room.pixel_pr_meter - 2}px`,
                    background: i >= openSeats ? seatTakenColor : seatAvailableColor,
                    border: '1px solid black',
                }}>
                </div>)}
            </div>
            <div className='seats right' style={{
                left: (table.width * room.pixel_pr_meter + 2) + 'px',
            }}>
                {new Array(Math.floor(table.active_seats / 2)).fill(0).map((_, i) => <div key={`right-${i}`}
                                                                                          className='chair' style={{
                    width: `${table.seat_size * room.pixel_pr_meter - 2}px`,
                    height: `${table.seat_size * room.pixel_pr_meter - 2}px`,
                    background: (i + table.active_seats / 2) >= openSeats ? seatTakenColor : seatAvailableColor,
                    border: '1px solid black',
                }}>
                </div>)}
            </div>
        </>}
        {table.type === 'Round' && <>
            {new Array(table.active_seats).fill(0).map((_, i) => <div key={`round-${i}`} className='chair' style={{
                position: 'absolute',
                width: `${(table.seat_size) * room.pixel_pr_meter}px`,
                height: `${(table.seat_size) * room.pixel_pr_meter}px`,
                left: `${0.5 + ((table.width - table.seat_size) / 2 * room.pixel_pr_meter) + Math.floor(Math.cos(i / table.active_seats * 2 * Math.PI) * ((table.width + table.seat_size + 0.1) * room.pixel_pr_meter / 2))}px`,
                top: `${0.5 + ((table.width - table.seat_size) / 2 * room.pixel_pr_meter) + Math.floor(Math.sin(i / table.active_seats * 2 * Math.PI) * ((table.width + table.seat_size + 0.1) * room.pixel_pr_meter / 2))}px`,
                background: i >= openSeats ? seatTakenColor : seatAvailableColor,
            }}>
            </div>)}
        </>}
        {table.type === 'Cinema' && <>
            <div className='seats' style={{
                left: ((table.width / 2 - table.seat_size / 2) * room.pixel_pr_meter) + 'px',
                pointerEvents: 'none',
            }}>
                {new Array(table.active_seats).fill(0).map((_, i) => <div key={`right-${i}`}
                                                                          className='chair' style={{
                    width: `${table.seat_size * room.pixel_pr_meter - 2}px`,
                    height: `${table.seat_size * room.pixel_pr_meter - 2}px`,
                    background: i >= openSeats ? seatTakenColor : 'white',
                }}>
                </div>)}
            </div>
        </>}
        <div className='seating-label-wrapper' style={{
            transform: `translateX(-50%) translateY(-50%) rotate(${-table.rotation}deg)`,
            width: 'auto',
            height: 'auto',
            position: 'absolute',
            top: '50%',
            left: '50%',
            color: table.text_color,
            padding: '2px',
            borderRadius: '2px',
            pointerEvents: 'none',
        }} onMouseDown={e => e.stopPropagation()}>
                <span>
                    {table.name}
                </span>
        </div>
    </Dragable>)
}
