export const prettyDate = date => {
    if (!date) return ''
    const d = new Date(date)
    return `${d.getDate()} ${d.toLocaleString('en-US', {month: 'short'})} ${d.getFullYear()}`
}

export const toDanishDate = date => {
    if (!date) return ''
    const d = new Date(date)
    return `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`
}

export const prettyTimeDifference = (d1, d2) => {
    const diff = (d1 - d2)/1000 // seconds

    if (diff < 10) {
        return 'just now'
    }
    if (diff < 3600) {
        return `${Math.floor(diff/60)} minutes ago.`
    }
    if (diff < 86400) {
        return `About ${Math.floor(diff/3600)} hours ago.`
    }
    const days = Math.floor(diff/86400)
    return `${days} day${days === 1 ? '' : 's'} ago.`
}
