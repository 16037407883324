import React, {useCallback, useMemo, useState} from 'react'

import './create-new-table-template.css';
import {doCreateTable, selectAllTables} from "../../slice/tableSlice";
import InputField from "../input-field/InputField";
import SimpleButton from "../simple-button/SimpleButton";
import {useDispatch, useSelector} from "react-redux";
import TableColorPickers from "../table-color-pickers/TableColorPickers";
import RoomTable from "../room-table/RoomTable";

export default function CreateNewTableTemplate({room_id, venue_id, onClose}) {
    const [newTableName, setNewTableName] = useState('');
    const [newTableMax, setNewTableMax] = useState(8);
    const [newTableType, setNewTableType] = useState(null);

    const [newTableLength, setNewTableLength] = useState(200);
    const [newTableWidth, setNewTableWidth] = useState(80);
    const [newTableRadius, setNewTableRadius] = useState(120);
    const [newTableHeight, setNewTableHeight] = useState(80);

    // cinema specific
    const [seatWidth, setSeatWidth] = useState(50);
    // end cinema specific

    const [surfaceColor, setSurfaceColor] = useState('#333333');
    const [textColor, setTextColor] = useState('white');
    const [seatColor, setSeatColor] = useState('#0059D4');

    const dispatch = useDispatch()

    const onCreateTable = useCallback(() => {
        let length = parseFloat(newTableLength) / 100
        let width = parseFloat(newTableWidth) / 100
        if (newTableType === 'Round') {
            length = parseFloat(newTableRadius) / 100
            width = parseFloat(newTableRadius) / 100
        } else if (newTableType === 'Cinema') {
            length = parseFloat(seatWidth) / 100 * newTableMax
            width = 0.8
        }
        dispatch(doCreateTable({
            venue_id,
            room_id,
            name: newTableName,
            max_seating: newTableMax,
            length,
            width,
            type: newTableType,
            position: {x: 100, y: 100},
            is_venue_template: true,
            surface_color: surfaceColor,
            text_color: textColor,
            seat_color: seatColor,
        }))
        setNewTableName('');
        setNewTableMax(8);
        onClose()
    }, [dispatch, newTableName, newTableMax, room_id, venue_id, newTableLength, newTableWidth, newTableType, onClose, newTableRadius,
              surfaceColor, textColor, seatColor, seatWidth]);

    const makeExample = useCallback((exampleTableType, useHardcoded=true) => {
        let length = 2
        let width = 0.8
        if (exampleTableType === 'Round') {
            length = 1.2
            width = 1.2
        } else if (exampleTableType === 'Cinema') {
            length = 3
            width = 0.8
        }
        const locSeatWidth = 0.5

        return <RoomTable
            table={{
                position: {x: 0, y: 0},
                rotation: 45,
                name: '',
                type: exampleTableType,
                length,
                width,
                max_seating: 6,
                active_seats: 6,
                seat_size: 0.5,
                surface_color: '#333333',
                text_color: 'white',
                seat_color: '#0059D4',
                seat_width: locSeatWidth
            }}
            draggable={false}
            pointer_events={false}
            key={`table_preview-template-menu-${exampleTableType}`}

            room={{
                pixel_pr_meter: 60 / (length + (exampleTableType === 'Round' ? locSeatWidth * 2 : 0)),
            }}
        />
    }, []);

    return (<div className='create-new-table-template'>
        <h2>Nyt bord</h2>
        <div className='horizontal'>
            <div className='side'>
                <label>Vælg en type bord</label>
                <div className='type-input-wrapper'>
                    <div className='example-wrapper' onClick={_ => setNewTableType('Square')}>
                        {makeExample('Square')}
                        <label className={newTableType === 'Square' ? 'selected' : ''}>Firkantet</label>
                    </div>
                    <div className='example-wrapper' onClick={_ => setNewTableType('Round')}>
                        {makeExample('Round')}
                        <label className={newTableType === 'Round' ? 'selected' : ''}>Rundt</label>
                    </div>
                    <div className='example-wrapper' onClick={_ => setNewTableType('Cinema')}>
                        {makeExample('Cinema')}
                        <label className={newTableType === 'Cinema' ? 'selected' : ''}>Stolerække</label>
                    </div>
                </div>

                <InputField title='Navn' value={newTableName} onChanged={setNewTableName}/>
                <InputField title='Antal pladser' value={newTableMax} onChanged={setNewTableMax}/>

                {newTableType === 'Round' && <>
                    <InputField title='Bordets radius (cm)' value={newTableRadius} onChanged={setNewTableRadius}/>
                    <InputField title='Højde (cm)' value={newTableHeight} onChanged={setNewTableHeight}/>
                </>}

                {newTableType === 'Square' && <>
                    <InputField title='Bordets længde (cm)' value={newTableLength} onChanged={setNewTableLength}/>
                    <InputField title='Bordet bredde (cm)' value={newTableWidth} onChanged={setNewTableWidth}/>
                    <InputField title='Højde (cm)' value={newTableHeight} onChanged={setNewTableHeight}/>
                </>}

                {newTableType === 'Cinema' && <>
                    <InputField title='Sædde bredde' value={seatWidth} onChanged={setSeatWidth}/>
                </>}

                <TableColorPickers
                    table_type={newTableType}
                    surfaceColor={surfaceColor}
                    onSelectedSurfaceColor={setSurfaceColor}
                    textColor={textColor}
                    onSelectedTextColor={setTextColor}
                    seatColor={seatColor}
                    onSelectedSeatColor={setSeatColor}
                />
            </div>
        </div>
        <SimpleButton className='create-btn' disabled={!newTableType} value='Opret' onClick={onCreateTable}/>
    </div>)
}
