import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import './measuring-tool.css'
import {Vector} from "vector2d";
import Line from "../line/Line";
import {VectorOperations} from "../VectorOperations";
import {useDispatch, useSelector} from "react-redux";
import {doUpdateRoom, selectZoom} from "../../slice/roomSlice";
import SimpleButton from "../simple-button/SimpleButton";
import InputField from "../input-field/InputField";

export default function MeasuringTool({room_id, closeSetScale}) {
    const [mouseStart, setMouseStart] = useState(null);
    const [mouseEnd, setMouseEnd] = useState(null);
    const [dragging, setDragging] = useState(false);

    const [lengthInputInMeters, setLengthInputInMeters] = useState(25);

    const zoom = useSelector(selectZoom)

    const toolRef = useRef(null);

    const dispatch = useDispatch();

    const distPosition = useMemo(() => {
        if (!mouseStart || !mouseEnd) return new Vector(0, 0);

        const length = mouseStart.clone().subtract(mouseEnd).length()
        if (length < 150) {
            return VectorOperations.moveTowardsPixels(mouseStart, mouseEnd, -25)
        }

        return VectorOperations.moveTowardsPercentage(mouseStart, mouseEnd, 50)
    }, [mouseStart, mouseEnd]);

    const mouseDown = useCallback(e => {
        if (e.ctrlKey) return;
        if (e.target.className !== toolRef.current.className) return;
        e.preventDefault();
        e.stopPropagation();

        setDragging(true);

        const clicked = new Vector(e.pageX, e.pageY)

        setMouseStart(clicked)
        setMouseEnd(clicked)
    }, [zoom]);

    const mouseUp = useCallback(e => {
        if (e.ctrlKey) return;
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);

        const clicked = new Vector(e.pageX, e.pageY)

        setMouseEnd(clicked)
    }, [zoom]);

    const mouseMove = useCallback(e => {
        if (e.ctrlKey) return;
        if (!dragging) return;
        e.preventDefault();
        e.stopPropagation();
        const clicked = new Vector(e.pageX, e.pageY)

        setMouseEnd(clicked)
    }, [dragging, zoom]);

    const length = useMemo(() => {
        if (!mouseEnd || !mouseStart) return 0;
        return Math.floor(mouseEnd.clone().subtract(mouseStart).length() / zoom)
    }, [mouseEnd, mouseStart, zoom]);

    return (<>
        <div ref={toolRef} className={'measuring-tool'} onMouseDown={mouseDown} onMouseUp={mouseUp}
             onMouseMove={mouseMove}>
            <Line start={mouseStart} end={mouseEnd}/>
            {length > 0 && <span style={{
                position: 'absolute',
                background: 'white',
                borderRadius: '8px',
                left: `${distPosition.x}px`,
                top: `${distPosition.y}px`,
                transform: 'translateX(-50%) translateY(-50%)',
                pointerEvents: 'none',
                padding: '0 2px',
            }}>{length}px</span>}
            <div className='explain-scale-popup'>
                <p>
                    Du er igang med at sætte en skalering på dette lokale. Vi skal bruge en reference på hvor mange
                    meter
                    der er mellem to punkter på billedet. Klik og træk for at vælge to punkter hvor du kender afstanden
                    i virkeligheden. Derefter kan du indtaste hvor mange meter det er, og gemme det.
                </p>
                <SimpleButton value='Annuller' onClick={closeSetScale}/>
            </div>
        </div>
        {length > 0 && <div style={{
            position: 'absolute',
            background: 'white',
            borderRadius: '8px',
            left: `${distPosition.x}px`,
            top: `${distPosition.y}px`,
            transform: 'translateX(-50%) translateY(100%)',
            padding: '0 2px',
            zIndex: 5,
        }}>
            <InputField title='How many meters is this in real life?' value={lengthInputInMeters}
                        onChanged={setLengthInputInMeters}/>
            <SimpleButton big
                          value={`Sæt skalering til ${Math.round(length / lengthInputInMeters * 100) / 100} pixel pr meter`}
                          onClick={_ => {
                              dispatch(doUpdateRoom({
                                  room_id,
                                  values: {
                                      pixel_pr_meter: length / lengthInputInMeters
                                  }
                              }))
                              closeSetScale()
                          }}/>
        </div>}
    </>)
}
