import React, {useMemo} from "react";
import ColorPicker from "../color-picker/ColorPicker";

export default function TableColorPickers({
                                              table_type,
                                              surfaceColor,
                                              onSelectedSurfaceColor,
                                              textColor,
                                              onSelectedTextColor,
                                              seatColor,
                                              onSelectedSeatColor,
                                          }) {
    const {surfaceColors, textColors, seatColors} = useMemo(() => {
        return {
            textColors: [
                '#FFFFFF',
                '#282828',
            ],
            surfaceColors: [
                '#D7D3D0',
                '#717171',
                '#9FCCF5',
                '#618B4C',
                '#CFDE48',
                '#BD5C2F',
                '#FD6B44',
                '#F277E1',
            ],
            seatColors: [
                '#5678FE'
            ]
        }
    }, []);

    return (<div className='table-color-pickers'>
        {table_type && table_type !== 'Cinema' && <ColorPicker
            label={'Bordfarve'}
            value={surfaceColor}
            availableColors={surfaceColors}
            onSelectColor={n => onSelectedSurfaceColor(n)}
        />}
        {table_type && table_type === 'Cinema' && <ColorPicker
            label={'Baggrundsfarve'}
            value={surfaceColor}
            availableColors={surfaceColors}
            onSelectColor={n => onSelectedSurfaceColor(n+'88')}
        />}
        <ColorPicker
            label={'Tekstfarve'}
            value={textColor}
            availableColors={textColors}
            onSelectColor={n => onSelectedTextColor(n)}
        />
        <ColorPicker
            label={'Seatfarve'}
            value={seatColor}
            availableColors={seatColors}
            onSelectColor={n => onSelectedSeatColor(n)}
        />
    </div>)
}
